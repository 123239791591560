<template>
    <Section
        class="banner"
        :class="slice.slice_label"
        :width="bannerWidth"
    >
        <div 
            class="container"
            :class="slice.primary.text_alignment"
        >
            <h1>{{ $prismic.asText(slice.primary.h1) }}</h1>
            <p v-if="$prismic.asText(slice.primary.teaser).length">{{ $prismic.asText(slice.primary.teaser) }}</p>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        bannerWidth() {
            if (this.slice.primary.width) {
                return this.slice.primary.width
            }
            return 'md';
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    .section-inner {

        .container {
            text-align: center;
            @include grid(1, 30px);

            h1 {
                word-break: break-word;
            }
        }

        .container.left { text-align: left; }
        .container.right { text-align: right; }
        .container.center { text-align: center; }
    }
}
</style>
