<template>
    <Section id="guide-section" :width="showResults ? 'lg' : 'md'"> 
        <div v-if="! showResults" id="guide" class="guide-container">
            <container 
                v-for="(question, questionKey, index) in questionsData"
                :id="`question${index}`"
                :key="index"
                :header="question.header"
                :active-value="question.activeValue"
                :error-text="question.errorText"
                :show-errors="showErrors"
            >
                <div 
                    class="question-buttons" 
                    :class="{'many-buttons': question.options.length > 2 }"
                >
                    <option-button 
                        v-for="(option, optionIndex) in question.options"
                        :key="optionIndex"
                        :title="option.title"
                        :value-key="option.valueKey"
                        :active="question.activeValue"
                        @setActive="(value) => { setActive(value, questionsData[questionKey]), scrollToNext(index) }"
                    />
                </div>
            </container>
            <container :id="`question${Object.keys(questionsData).length}`" :header="$translate('guide_form_header', 'Fyll i dina personsuppgifter')">
                <form class="personal-info-form">
                    <template 
                        v-for="(question, key) in personalInfoData"
                    >
                        <dynamic-input
                            v-if="!question.disabled"
                            :key="key"
                            class="personal-info-form-question"
                            :form-key="question.formKey"
                            :type="question.type"
                            :allow-only-numbers="question.onlyNumbers"
                            :label="question.label"
                            :tooltip-text="question.tooltip"
                            :value="question.value"
                            :placeholder="question.placeholder"
                            :error-text="question.errorText"
                            :error="showErrors && ! question.validate(question.value)"
                            @set="(_, input) => question.value = input"
                        />
                    </template>
                    <button class="btn btn-orange" @click.prevent="submit()">
                        <span>{{ $translate('guide_button_submit', 'Visa kreditkorten som passar för mig') }}</span>
                    </button>
                    <div 
                        class="terms rich-text"
                        v-html="$prismic.asHtml($translate('guide_terms'))"
                    />
                </form>
            </container>
            
            <progressbar element-id="guide" screen-size-offset="middle" />
        </div>
        <div v-else id="result">
            <!-- hard coded logic for payment remarks -->
            <template 
                v-if="hasPaymentRemarks && 
                    (paymentRemarksLenders.length > 0 || paymentRemarksCards.length > 0)"
            >
                <h2 class="payment-remarks-results-header">{{ $translate('guide_result_header', 'Ditt resultat') }}</h2>
                <p>
                    {{ $translate(
                        'guide_payment_remarks_result_description', 
                        'Inga kreditkort accepterar betalningsanmärkningar, här får du därför lite andra alternativ.'
                    ) }}
                </p>
                <template v-if="paymentRemarksLenders.length > 0">
                    <h3 class="payment-remarks-results-sub-header">
                        {{ $translate(
                            'guide_payment_remarks_result_lenders_header', 
                            'Kontokrediter som passar dig'
                        ) }}
                    </h3>
                    <ul class="lenders-list">
                        <Lender 
                            v-for="lender in getPaymentRemarksLenders()"
                            :key="lender.id"
                            :lender="lender"
                        />
                    </ul>
                </template>
                <template v-if="paymentRemarksCards.length > 0">
                    <h3 class="payment-remarks-results-sub-header">
                        {{ $translate(
                            'guide_payment_remarks_result_cards_header', 
                            'Betalkort som passar dig'
                        ) }}
                    </h3>
                    <ul class="card-list">
                        <card
                            v-for="(card, index) in paymentRemarksCards"
                            :id="card.id"
                            :key="index"
                        />
                    </ul>
                </template>
            </template>
            <template v-else>
                <h2 class="results-header">{{ $translate('guide_result_header', 'Ditt resultat') }}</h2>
                <ul class="card-list">
                    <card
                        v-for="(card, index) in filteredList()"
                        :key="card.id"
                        :card="card"
                        :highlighted-text="index === 0 ? $translate('guide_best_match', 'Bästa matchning') : ''"
                        :class="{'highlighted': index === 0}"
                    />
                </ul>
            </template>
            <div class="under-list-utilities">
                <feedback-box />
                <button class="btn btn-md btn-green btn-redo-guide" @click="redoGuide()">
                    {{ $translate('guide_redo_test_button', 'Gör om test') }}
                </button>
            </div>
        </div>
    </Section>
</template>

<script>
import QuestionsData from '@/components/card-guide/QuestionsData.js';
import ScoreLogic from '@/components/card-guide/ScoreLogic.js';

import Container from '@/components/card-guide/QuestionContainer.vue';
import OptionButton from '@/components/card-guide/QuestionOptionButton.vue';
import DynamicInput from '@/components/slices/apply-for-cards/forms/form-components/DynamicInput.vue';
import Card from '@/components/slices/list/list-item/ListItemCard.vue';
import Lender from '@/components/slices/list/list-item/ListItemLender.vue';
import Progressbar from '@/components/features/Progressbar.vue';
import FeedbackBox from '@/components/card-guide/FeedbackBox.vue';

export default {
    components: {
        Container,
        OptionButton,
        DynamicInput,
        Card,
        Progressbar,
        FeedbackBox,
        Lender
    },
    mixins: [QuestionsData, ScoreLogic],
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showErrors: false,
            showResults: false,
            dataSaved: false,
        };
    },
    computed: {
        paymentRemarksCards() {
            if ((! this.showResults) || (! this.hasPaymentRemarks)) {
                return [];
            }
            return this.getPaymentRemarksCards();
        },
        paymentRemarksLenders() {
            if ((! this.showResults) || (! this.hasPaymentRemarks)) {
                return [];
            }

            return this.getPaymentRemarksLenders();
        },
    },
    watch: {
        '$route'() {
            if (this.$route.query.benefits) {
                this.restoreSavedData();
            }
            else if (this.showResults) {
                this.redoGuide();
            }
        }
    },
    mounted() {
        if (this.$route.query.benefits) {
            this.restoreSavedData();
        }
    },
    methods: {
        setActive(value, data) {
            data.activeValue = value;
        },
        scrollToNext(currentQuestionIndex) {
            if (this.showErrors) {
                return this.scrollToError();
            }
            this.$scrollTo(`#question${currentQuestionIndex + 1}`, 500, {offset: -100});
        },
        submit() {
            if (this.validateAnswers() && this.validatePersonalInfo()) {
                this.setResults();
                if (!this.dataSaved) {
                    this.sendTrackingData();
                }
                this.saveResults();
                this.showResults = true;
                this.showErrors = false;
                this.$nextTick(() => this.$scrollTo('#guide-section', 500, {offset: -50}));
            } 
            else {
                this.showErrors = true;
                this.scrollToError();
            }
        },
        validateAnswers() {
            return ! Object.values(this.questionsData).some(question => {
                return question.activeValue === '';
            });
        },
        validatePersonalInfo() {
            let personalInfoData = Object.values(this.personalInfoData);
            if (this.dataSaved) {
                personalInfoData = personalInfoData.filter(input => !input.disabled);
            }
            return ! personalInfoData.some(input => {
                return ! input.validate(input.value);
            });
        },
        scrollToError() {
            const errorIndex = Object.values(this.questionsData).findIndex(question => {
                return question.activeValue === '';
            });
            if (errorIndex >= 0) {
                return this.$nextTick(() => this.$scrollTo(`#question${errorIndex}`, 500, {offset: -100}));
            }
            return this.$nextTick(() => this.$scrollTo(`#question${Object.keys(this.questionsData).length}`, 500, {offset: -100}));
        },
        formatPhoneNumber(phoneNumber) {
            if (this.$isNorway()) {
                if (phoneNumber.startsWith('47')) {
                    return phoneNumber;
                }
                if (phoneNumber.startsWith('0047')) {
                    return phoneNumber.replace('0047', '47');
                }
                return `47${phoneNumber}`;
            }
            return phoneNumber;
        },
        sendTrackingData() {
            const payload = {
                // eslint-disable-next-line camelcase
                guide_id: process.env.BROKER_GUIDE_ID,
                user: {
                    email: this.personalInfoData.email.value,
                    // eslint-disable-next-line camelcase
                    phone_number: this.formatPhoneNumber(this.personalInfoData.phone.value),
                },
                questionnaire: {
                    // eslint-disable-next-line camelcase
                    monthly_income: Number(this.personalInfoData.income.value),
                    // eslint-disable-next-line camelcase
                    payment_remarks: this.questionsData.paymentRemarks.activeValue === 'yes'
                }
            };
            payload.tracking = this.$tracking.getTrackingObject();
            
            this.$axios.post('/guides', payload)
                .then(() => {
                    this.$event.cardGuideSubmitted();
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        },
        redoGuide() {
            this.$router.push({ query: {} });
            this.resetScore();
            this.showResults = false;
            Object.values(this.questionsData).forEach(question => {
                question.activeValue = '';
            });
            this.$nextTick(() => this.$scrollTo('#question0', 500, {offset: -100}));
        },
        saveResults() {
            const query = {};
            Object.keys(this.questionsData).forEach((key) => {
                query[key] = this.questionsData[key].activeValue;
            });

            query.income = this.personalInfoData.income.value;
            query.birthYear = this.personalInfoData.birthYear.value;
            this.$router.push({query: query});
        },
        restoreSavedData() {
            this.dataSaved = true;
            Object.keys(this.questionsData).forEach((key) => {
                if (this.$route.query[key]) {
                    this.questionsData[key].activeValue = this.$route.query[key];
                }
            });
            this.personalInfoData.income.value = this.$route.query.income;
            this.personalInfoData.birthYear.value = this.$route.query.birthYear;
            // Disable inupts unrelated to the score calculation 
            this.personalInfoData.email.disabled = true;
            this.personalInfoData.phone.disabled = true;
            if (this.validateAnswers() && this.validatePersonalInfo()) {
                this.setResults();
                this.showResults = true;
                this.showErrors = false;
            }
            else {
                this.showErrors = true;
                this.scrollToError();
            }
        }
    },
};
</script>

<style lang="scss" scoped>
    .guide-container {
        display: flex;
        flex-direction: column;
        gap: 80px;
    }

    .question-container {
        counter-increment: counter;
        &::after {
            content: counter(counter);
            position: absolute;
            top: -20px;
            left: -20px;
            border: 7px solid $white;
            @include flex;
            border-radius: 100px;
            font-weight: bold;
            @include size(30px);
            background: $green;
            color: $white;
        }
    }

    .question-buttons{
        @include grid(1, 10px);

        @include device(pad) {
            padding: 0 40px;
            @include grid(2, 10px);
        }
        &.many-buttons {
            @include device(desktop) {
                @include grid(3, 10px);
                padding: 0 40px;
            }
        }
    }
    .personal-info-form {
        @include flex(); 
        flex-direction: column;
        padding: 20px 0;
        gap: 30px;
        max-width: 400px;
        margin: 0 auto;
        &-question {
            width: 100%;
        }
        button {
            padding: 20px;
            width: 100%;
            span {
                @include font-size(16px);
            }
        }
    }

    .terms ::v-deep p {
        @include font-size(14px);
        &:first-child {
            margin: 0;
        }
    }

    .results-header {
        width: 100%;
        text-align: left;
        font-size: calc(1.375rem + 1.5vw);
        @include spacing(margin,10,bottom);
    }
    .payment-remarks-results-header {
        width: 100%;
        text-align: left;
        font-size: calc(1.375rem + 1.5vw);
        @include spacing(margin, 3, bottom);
    }
    .payment-remarks-results-sub-header {
        width: 100%;
        text-align: left;
        @include spacing(margin, 10, top);
        @include spacing(margin, 5, bottom);
    }
    .card-list {
        width: 100%;
        @include grid(1, 30px);

        .highlighted{
            border-color: $green;
            border-width: 2px;
        }
    }
    .lenders-list {
        width: 100%;
        @include grid(1, 30px);
        margin-bottom: 50px;
    }
    .under-list-utilities {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 20px;
        gap: 20px;
        @include device(pad) {
            margin-top: 30px;
            grid-template-columns: 2fr 1fr;
        }
        .btn-redo-guide {
            width: 100%;
            height: fit-content;
            grid-row: 1;
            @include device(pad) {
                width: auto;
                margin-left: auto;
                grid-row: unset;
            }
        }
    }
</style>