<template>
    <Section
        class="banner"
        width="md"
    >
        <prismic-image
            :img="document.data.post_image"
            class="banner-image"
            :lazy="false"
            w="860"
            h="500"
        />

        <div class="banner-content">
            <h1>{{ document.data.post_title }}</h1>

            <span
                v-if="postCategory"
                class="post-category font-header"
            >
                {{ postCategory }}
            </span>

            <div class="banner-content__footer">
                <span v-if="document.data.post_author.id"><i class="fa-solid fa-user" />{{ authorName(document.data.post_author.id) }}</span>

                <timestamp :string="document.last_publication_date" />

                <read-time :data="document" />
            </div>
        </div>
    </Section>
</template>

<script>
import ReadTime from '@/components/features/ReadTime.vue';
import Timestamp from '@/components/features/Timestamp.vue';
export default {
    components: { Timestamp, ReadTime },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        postCategory() {
            if (this.document.data.post_category.id) {
                return this.$store.state.postCategories[this.document.data.post_category.id].data.category;
            }
        }
    },
    methods: {
        authorName(id) {
            return this.$store.state.authors[id].data.name;
        }
    }
};
</script>

<style lang="scss" scoped>
.banner {
    padding: 60px 20px;
    background: $graybg;
    margin-top: 60px;

    &-content {
        left: 0;
        margin-top: -140px;
        @include grid(1,20px);
        background: $graybg;
        @include border-radius(20px,top-right);
        position: relative;
        box-sizing: border-box;

        @media screen and (max-width: 700px) {
            padding: 20px 20px 0 0;
            max-width: calc(100% - 40px);
        }

        @media screen and (min-width: 700px) {
            padding: 30px 30px 0 0;
            max-width: calc(100% - 100px);
        }

        h1 { color: $green; }

        .post-category { font-weight: bold; }

        &__footer {
            @include grid(1,10px);

            @media screen and (min-width: 700px) {
                @include flex(start);
                .timestamp { padding: 0 20px; }
            }

            i {
                margin-right: 5px;
            }
        }
    }

    &-image {
        @include border-radius(10px);
        width: 100%;
        height: 300px;
        object-fit: cover;

        @media screen and (min-width: 700px) {
            width: calc(100% - 40px);
            height: 500px;
            margin-left: 40px;
        }
    }
}
</style>
