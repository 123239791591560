<template>
    <Section
        class="banner"
        width="lg"
    >
        <div class="container">
            <div class="container-inner">
                <h1>{{ $prismic.asText(slice.primary.h1) }}</h1>
                <p v-if="$prismic.asText(slice.primary.teaser).length">{{ $prismic.asText(slice.primary.teaser) }}</p>
            </div>
        </div>

        <div class="container">
            <div class="container-inner">
                <nuxt-link :to="$prismic.linkResolver(card)">
                    <prismic-image
                        :img="card.data.image"
                        w="350"
                        h=""
                        class="card-img"
                        :lazy="false"
                    />
                </nuxt-link>

                <span class="font-header">{{ card.data.title }}</span>

                <ButtonTarget
                    :link="card"
                    background="orange"
                    size="md"
                />
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.slice.primary.featured_card.id];
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    background: $graybg;

    .section-inner {
        @include grid(1, 30px);

        @include device(pad) {
            @include grid(2, 60px);
        }

        .container {
            &:first-child {
                @include flex(start);

                .container-inner {
                    @include grid(1,30px);
                    @include device(mobile) {
                        text-align: center;
                    }
                }
            }

            &:last-child {
                position: relative;
                @include flex;

                .container-inner {
                    @include spacing(padding,6);
                    @include border-radius(20px);
                    box-shadow: $bxs;
                    @include border;
                    background: $white;
                    @include grid(1,30px);

                    img {
                        @include border-radius(15px);
                    }

                    .font-header {
                        text-align: center;
                        @include font-size(24px);
                    }
                }
            }
        }
    }
}
</style>
