<template>
    <Section
        :class="slice.slice_label"
        width="lg"
    >
        <div class="container">
            <div class="container-inner">
                <section-header
                    :title="slice.primary.title"
                    :teaser="slice.primary.teaser"
                />
                
                <button-nuxt
                    v-if="slice.primary.link.id"
                    :link="slice.primary.link"
                    :text="slice.primary.link_text"
                    size="md"
                    background="green"
                />
            </div>
        </div>

        <div class="container">
            <prismic-image
                :img="slice.primary.image"
                w="400"
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        .section-inner {
            @include grid(1, 20px);
            @include device(pad) {
                @include grid(2, 80px);
            }

            .container {
                @include flex;

                &-inner {
                    @include flex(start);
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }
</style>
