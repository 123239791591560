<template>
    <Section 
        :class="slice.slice_label"
        width="lg"
    >
        <section-header
            id="cards-header"
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />

        <ul class="card-list">
            <card
                v-for="card in cardsToShow"
                :key="card.id"
                :card="card"
                :category-data-key="categoryDataKey"
            />
        </ul>
        <trust-us />
    </Section>
</template>

<script>
import Card from './list-item/ListItemCard.vue';
import TrustUs from '@/components/TrustUs.vue';
import { orderBy } from 'lodash';
export default {
    components: { Card, TrustUs },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        selectedCards() {

            let cards = [];
            if (this.slice.primary.list_preset.id) {
                cards = this.$store.state.lists[this.slice.primary.list_preset.id].data.cards;
                // return map(list, 'card');
            }
            else {
                cards = this.slice.items;
            }

            cards = cards
                .map(iterator => iterator.card)
                .filter(card => card.id && card.type !== 'broken_type');

            return cards;
        },
        cards() {
            if (this.selectedCards.length) {
                return this.selectedCards;
            }

            return orderBy(this.$store.state.cards, 'data.title', 'asc');
        },
        cardsToShow() {
            return this.cards
                .map(card => this.$store.state.cards[card.id])
                .filter(card => ! card.data.lists_disabled);
        },
        categoryDataKey() {
            return this.slice.primary.category_data_key;
        },
    }
};
</script>

<style lang="scss" scoped>
    section {

        .card-list {
            width: 100%;
            @include grid(1, 30px);
        }
    }
</style>
