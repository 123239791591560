<template>
    <li class="item-container">
        <div class="header-section">
            <nuxt-link class="image-container" :to="$prismic.linkResolver(card)">
                <prismic-image 
                    :img="card.data.image"
                    :alt="card.data.title"
                    width="200"
                />
            </nuxt-link>
            <nuxt-link 
                class="header" 
                :to="$prismic.linkResolver(card)"
            >
                <h3 class="h2">{{ title }}</h3>
            </nuxt-link>
            <div 
                class="rich-text" 
                v-html="$prismic.asHtml(item.text)"
            />
        </div>
        <data-table 
            v-if="dataType === 'card-data'" 
            :model="model" 
        />
        <pros-list 
            v-else 
            :pros="pros"
        />
        <div class="button-container">
            <ButtonTarget
                :link="card"
                background="orange"
                size="md"
            />
        </div>
    </li>
</template>

<script>
import DataTable from './DataTable.vue';
import ProsList from './ProsList.vue';
export default {
    components: {
        DataTable,
        ProsList
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        dataType: {
            type: String,
            required: true,
        },
        categoryDataKey: {
            type: String,
            required: false,
            default: 'general'
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.item.card.id];
        },
        model() {
            return new this.$models.CreditCard(this.card, this.$store);
        },
        title() {
            if (this.$validateText(this.item.title)) {
                return this.item.title.replace('{index}', this.index);
            }
            return this.index + '. ' + this.card.data.title;
        },
        pros() {
            if (this.$validateText(this.item.pros)) {
                return this.item.pros;
            }
            return this.$getCategoryData(this.card, this.categoryDataKey).pros; 
        }
    }
};
</script>

<style lang="scss" scoped>
    .item-container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .header-section {
            @include device(mobile) {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
            }
            .header {
                @include spacing(margin, 4, bottom);
                order: -1;
                width: fit-content;
                display: block;
            }
            .image-container { 
                @include device(mobile) {
                    margin: 0 auto;
                }
                @include device(pad) {
                    float: right;
                    margin-left: 20px;
                    img {
                        width: 150px;
                    }
                }
            }
        }
        .button-container {
            @include device(pad) {
                display: flex;
            }
        
        }
    }
</style>