// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-068e5673]{font-family:\"Open Sans\"}.font-header[data-v-068e5673]{font-family:\"Hero\"}.item-container[data-v-068e5673]{display:flex;flex-direction:column;grid-gap:1.5rem;gap:1.5rem}@media screen and (max-width:600px){.item-container .header-section[data-v-068e5673]{display:flex;flex-direction:column;grid-row-gap:10px;row-gap:10px}}@media screen and (min-width:mobile){.item-container .header-section[data-v-068e5673]{display:flex;flex-direction:column;grid-row-gap:10px;row-gap:10px}}.item-container .header-section .header[data-v-068e5673]{box-sizing:border-box;order:-1;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;display:block}@media screen and (max-width:600px){.item-container .header-section .header[data-v-068e5673]{margin-bottom:16px}}@media screen and (min-width:600px){.item-container .header-section .header[data-v-068e5673]{margin-bottom:16px;margin-bottom:20px}}@media screen and (max-width:600px){.item-container .header-section .image-container[data-v-068e5673]{margin:0 auto}}@media screen and (min-width:mobile){.item-container .header-section .image-container[data-v-068e5673]{margin:0 auto}}@media screen and (min-width:600px){.item-container .header-section .image-container[data-v-068e5673]{float:right;margin-left:20px}.item-container .header-section .image-container img[data-v-068e5673]{width:150px}}@media screen and (min-width:pad){.item-container .header-section .image-container[data-v-068e5673]{float:right;margin-left:20px}.item-container .header-section .image-container img[data-v-068e5673]{width:150px}}@media screen and (min-width:600px){.item-container .button-container[data-v-068e5673]{display:flex}}@media screen and (min-width:pad){.item-container .button-container[data-v-068e5673]{display:flex}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
